/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard.js";
import Projects from "./views/projects/Projects";
import CreateProject from "./views/projects/CreateProject";
import ProjectSettings from "./views/projects/ProjectSettings";
import Categories from "./views/content/Categories";
import Articles from "./views/content/Articles";
import Media from "./views/content/Media";
import ArticleView from "./views/content/ArticleView";
import Customizations from "./views/customizations/Customizations";
import ConfigVersions from './views/customizations/ConfigVersions'
import AppCustomizations from "./views/appcustomizations/Customizations";
import Login from "./views/auth/Login";
import Domains from "./views/projects/Domains";
import CreateDomain from "./views/projects/CreateDomain";
import Publishers from "./views/dashboards/Publishers";
import AwsResources from "./views/projects/AwsResources";
import Users from "./views/projects/Users";
import InvitedUsers from "./views/projects/InvitedUsers";
import NotificationsDashboard from "./views/notifications/NotificationsDashboard.js";
import JobsDashboard from "./views/jobs/JobsDashboard.js";
import ScheduleNewJob from "./views/jobs/ScheduleNewJob.js";
import RescheduleJob from "./views/jobs/RescheduleJob.js";
import EditJob from './views/jobs/EditJob';
import FeatureGroups from "./views/saas/FeatureGroups.js";
import Features from "./views/saas/Features.js";
import CreateFeatureGroup from "./views/saas/CreateFeatureGroup.js";
import CreateFeature from "./views/saas/CreateFeature.js";
import PlanGroup from "./views/saas/plan_group/PlanGroups.js";
import CreatePlanGroup from "./views/saas/plan_group/CreatePlanGroup.js";
import Plan from "./views/saas/plan/Plan.js";
import CreatePlan from './views/saas/plan/CreatePlan.js'
import PlanDetails from './views/saas/plan/PlanDetails.js'
import EditFeature from "./views/saas/EditFeature.js";
import EditPlanGroup from "./views/saas/plan/EditPlan.js";
import Contracts from "./views/contract/Contracts.js";
import CreateContract from "./views/contract/Create.js";
import ProjectReport from "./views/dashboards/ProjectReport.js";
import PublisherReport from "./views/report/Publisher"
import TransactionDetails from "./views/transaction/TransactionDetails.js";
import Transactions from "./views/transaction/Transactions.js";
import PaidArticlesTransactions from "./views/transaction/PaidArticlesTransactions.js";
import Template from "./views/template/Template.js";
import AppTemplate from "./views/template/AppTemplate.js";
import CreateAppTemplate from "./views/template/CreateAppTemplate.js";
import CreateTemplate from "./views/template/Create.js";
import EditTemplate from "./views/template/customizations/Customizations.js";
import EditAppTemplate from "./views/template/appcustomizations/EditAppTemplate.js";
import PlanGroupTemplates from "./views/saas/plan_group/Template.js";
import InviteEnterprisePlan from "./views/contract/InviteEnterprisePlan.js";
import PlanInvitations from "./views/contract/Invitations.js";
import PluginUsers from "./views/plugin/PluginUsers.js";
import ProjectsPluginInfo from './views/plugin/ProjectsPluginInfo.js'
import FAQs from "./views/faq/faqs.js";
import CreateFAQ from "./views/faq/CreateFAQ.js";
import EditFAQ from "./views/faq/EditFAQ.js";
import FAQGroups from './views/faq/FAQGroups.js'
import CreateFAQGroup from './views/faq/CreateFAQGroup.js'
import DailyStats from './views/projects/DailyStats.js';
import MonthlyStats from './views/projects/MonthlyStats.js';
import AppSettings from './views/projects/AppSettings.js';
import AppTemplateSetting from './views/projects/AppTemplateSetting.js'
import CacheClean from "./views/cacheclean/CacheClean.js";
import ScheduleCacheCleanJob from "./views/cacheclean/ScheduleCacheCleanJob.js";
import AppBuild from './views/appbuild/AndroidAppBuild.js';
import AddProjectToQueue from "./views/manageprojects/AddProjectToQueue";
import DeleteProjectFromQueue from "./views/manageprojects/DeleteProjectsFromQueue";
import AppConfigVersion from './views/appconfig/AppConfigVersion'
import AppPlatformTab from './views/appbuild/AppPlatformTab'
// import AppConfigVersion from './views/appconfig/AppConfigVersion';
import IOSAppManageUsers from './views/appbuild/IOSAppUsers'
import CreateServiceStack from "./views/servicestack/CreateServiceStack.js";
import ServiceStackDashboard from "./views/servicestack/ServiceStacks.js";
import ServiceStackDetails from "./views/servicestack/ServiceStackDetails";
import ProjectAlertDashboard from "./views/projectAlerts/ProjectAlert.js";

import WebstoryView from "./views/content/WebstoryView.js";
import WhatsAppSendMessage from './views/whatsapp/WhatsAppSendMessage.js';
import WhatsappMessages from './views/whatsapp/Messages.js';
import WPWebstories from './views/content/WPWebstories.js';
import CouponCodeList from './views/couponcode/CouponCodeList.js';
import CreateCoupon from './views/couponcode/CreateCoupon.js'



import EditCoupon from './views/couponcode/EditCoupon.js';
import ProjectFeatures from './views/projects/ProjectFeatures.js';
import UserDetails from "./views/users/UserDetails.js";
import CreateUser from "./views/users/CreateUser.js";
import EditUserDetails from "./views/users/EditUserDetails.js";
import PermissionGroupDetails from "./views/permission/PermissionGroupDetails.js";
import CreatePermissionGroup from "./views/permission/CreatePermissionGroup.js";
import EditPermissionGroup from "./views/permission/EditPermissionGroup.js";
import WhastappAlerts from "./views/whatsapp/WhatsappAlerts";

import Subscriptions from "./views/subscription/Subscription.js";
import ZohoDeskTickets from "./views/zoho-desk/Tickets.js";
import ZohoDeskCreateTicket from "./views/zoho-desk/CreateTicket.js";
import ZohoDeskViewTicket from "./views/zoho-desk/ViewTicket.js";
import ZohoDeskEditTicket from "./views/zoho-desk/EditTicket.js";
import ConfigFeatures from "./views/projects/ConfigFeatures.js";
import PaymentLinks from "./views/contract/PaymentLinks.js";
import CreatePaymentLinks from "./views/contract/CreatePaymentLink.js";
import ViewPaymentLink from "./views/contract/ViewPaymentLink.js";
import AppBuilds from "./views/appbuild/AppBuilds.js";
import JwplayerProjects from "./views/jwplayerDashboard/JwplayerDashboard.js";
import CTVDashboard from './views/ctv/Dashboard.js'
import CTVGeneralSettings from './views/ctv/GeneralSettings.js'
import ImporterLogs from "./views/ctv/ImporterLogs.js";
import CTVAppTemplate from './views/ctv/Template/Templates.js';
import CreateCTVAppTemplate from './views/ctv/Template/CreateTemplate.js';
import EditCTVAppTemplate from './views/ctv/Template/Customization/EditAppTemplate.js';
import SaveAppTemplate from "./views/ctv/Template/Customization/SaveNewTemplate.js";
import UpdateTemplateDetails from "./views/template/EditTemplate.js";
import GcpResourcesDashboard from './views/gcpresources/GcpResourcesDashboard.js';
import GenAIQuotaList from './views/genai/QuotaList.js'
import GenAICredits from "./views/genai-credits/Credits.js";
import CreateGenAICredits from "./views/genai-credits/Create.js";
import GamesSettings from "./views/projects/GamesSettings.js";
import GamesListing from "./views/games/GamesListing.js";
import CreateGames from "./views/games/CreateGames.js";
import RedirectionUrls from "./views/dashboards/RedirectionUrls.js";
import UserProfileDashboard from "./views/dashboards/UserProfileDashboard.js";
import YoutubeApprovals from "./views/youtube/index.js";
import SubscriptionsMembership from "./views/membership/Subscription.js";

import TransactionsMembership from "./views/membership/Transactions.js";
import UserProfileDetailedView from "./views/dashboards/UserProfileDetailedView.js";


const routes = [
  {
    path: "/home",
    name: "Dashboard",
    visible: true,
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/dashboard",
  },
  {
    path: "/publishers",
    name: "Publishers",
    visible: true,
    icon: "nc-icon nc-single-02",
    component: Publishers,
    layout: "/dashboard",
  },
  {
    path: "/projects",
    name: "Projects",
    visible: true,
    icon: "nc-icon nc-single-02",
    component: Projects,
    layout: "/project",
  },
  {
    collapse: true,
    visible: true,
    name: "Project Features",
    icon: "nc-icon nc-chart-pie-36",
    state: "projectFeatureCollapse",
    views: [
      {
        path: "/features",
        name: "Project Features",
        visible: true,
        mini: "F",
        component: ProjectFeatures,
        layout: "/project",
      },
      {
        path: "/config-features",
        name: "Config Features",
        visible: true,
        mini: "C",
        component: ConfigFeatures,
        layout: "/project",
      },
    ]
  },
  {
    path: "/create",
    visible: false,
    name: "Create Project",
    icon: "nc-icon nc-single-02",
    component: CreateProject,
    layout: "/project",
  },
  {
    path: "/settings",
    visible: false,
    name: "Project Settings",
    icon: "nc-icon nc-single-02",
    component: ProjectSettings,
    layout: "/project",
  },
  {
    path: "/games",
    visible: false,
    name: "Project Settings",
    icon: "nc-icon nc-single-02",
    component: GamesSettings,
    layout: "/project",
  },
  {
    collapse: true,
    visible: true,
    name: "Content Settings",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsContentCollapse",
    views: [
      {
        path: "/categories",
        name: "Categories",
        visible: true,
        mini: "C",
        component: Categories,
        layout: "/contentsettings",
      },
      {
        path: "/articles",
        name: "Articles",
        visible: true,
        mini: "A",
        component: Articles,
        layout: "/contentsettings",
      },
      {
        path: "/wpwebstories",
        name: "WPWebstories",
        visible: true,
        mini: "W",
        component: WPWebstories,
        layout: "/contentsettings",
      },
      {
        path: "/media",
        name: "Media",
        visible: true,
        mini: "M",
        component: Media,
        layout: "/contentsettings",
      },
      {
        path: "/article_view",
        name: "Article View",
        visible: false,
        mini: "A",
        component: ArticleView,
        layout: "/contentsettings",
      },
      {
        path: "/webstory_view",
        name: "Webstory View",
        visible: false,
        mini: "W",
        component: WebstoryView,
        layout: "/contentsettings",
      }
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Sortd Apps",
    icon: "nc-icon nc-mobile",
    state: "sortdAppsCollapse",
    views: [
      {
        path: "/list",
        name: "Apps Build",
        visible: true,
        mini: "A",
        component: AppBuilds,
        layout: "/appbuild",
      },
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Google Cloud",
    icon: "nc-icon nc-cloud-upload-94",
    state: "googleCloudCollapse",
    views: [
      {
        path: "/gcpresources",
        name: "GCP Resources",
        visible: true,
        mini: "GCP",
        component: GcpResourcesDashboard,
        layout: "/project"
      }
    ]
  },
  {
    path: "/customizations",
    visible: false,
    name: "Customizations",
    icon: "nc-icon nc-controller-modern",
    component: Customizations,
    layout: "/config",
  },
  {
    path: "/versions",
    visible: false,
    name: "Config Versions",
    icon: "nc-icon nc-controller-modern",
    component: ConfigVersions,
    layout: "/config",
  },
  {
    path: "/appcustomizations",
    visible: false,
    name: "App Customizations",
    icon: "nc-icon nc-controller-modern",
    component: AppCustomizations,
    layout: "/config",
  },
  {
    path: "/appconfigversions",
    visible: false,
    name: "App Config Versions",
    icon: "nc-icon nc-controller-modern",
    component: AppConfigVersion,
    layout: "/config",
  },
  {
    path: "/manage-tester",
    visible: false,
    name: "IOS App Manage Users",
    icon: "nc-icon nc-controller-modern",
    component: IOSAppManageUsers,
    layout: "/appbuild",
  },
  {
    path: "/awsresources",
    visible: true,
    name: "AWS Resources",
    icon: "nc-icon nc-controller-modern",
    component: AwsResources,
    layout: "/project",
  },
  {
    path: "/dashboard",
    visible: true,
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: NotificationsDashboard,
    layout: "/notifications",
  },
  {
    path: "/dashboard",
    visible: true,
    name: "Jobs",
    icon: "nc-icon nc-alert-circle-i",
    component: JobsDashboard,
    layout: "/jobs",
  },
  {
    path: "/create",
    visible: false,
    name: "ScheduleNewJob",
    icon: "nc-icon nc-alert-circle-i",
    component: ScheduleNewJob,
    layout: "/jobs",
  },
  {
    path: "/reschedule/:jobId",
    visible: false,
    name: "RescheduleScheduleJob",
    icon: "nc-icon nc-alert-circle-i",
    component: RescheduleJob,
    layout: "/jobs",
  },
  {
    path: "/edit-job/:jobId",
    visible: false,
    name: "EditJob",
    icon: "nc-icon nc-alert-circle-i",
    component: EditJob,
    layout: "/jobs",
  },
  {
    path: "/dashboard",
    visible: false,
    name: "AppBuild",
    icon: "nc-icon nc-alert-circle-i",
    component: AppPlatformTab,
    layout: "/appbuild",
  },
  {
    path: "/login",
    visible: false,
    name: "Login",
    icon: "nc-icon nc-single-02",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/createdomain",
    visible: false,
    name: "Create Domain",
    icon: "nc-icon nc-single-02",
    component: CreateDomain,
    layout: "/project",
  },
  {
    path: "/domains",
    visible: false,
    name: "Project Domains",
    icon: "nc-icon nc-single-02",
    component: Domains,
    layout: "/project",
  },
  {
    path: "/users",
    visible: false,
    name: "Project Users",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: "/project",
  },
  {
    path: "/invitedusers",
    visible: false,
    name: "Project Users",
    icon: "nc-icon nc-single-02",
    component: InvitedUsers,
    layout: "/project",
  },
  {
    path: "/createfeaturegroup",
    visible: false,
    name: "Create Feature Group",
    icon: "nc-icon nc-single-02",
    component: CreateFeatureGroup,
    layout: "/saas",
  },
  {
    path: "/createfeature",
    visible: false,
    name: "Create Feature",
    icon: "nc-icon nc-single-02",
    component: CreateFeature,
    layout: "/saas",
  },
  {
    path: "/create-plan-group",
    visible: false,
    name: "Create Plan Group",
    icon: "nc-icon nc-single-02",
    component: CreatePlanGroup,
    layout: "/saas",
  },

  {
    path: "/create-plan",
    visible: false,
    name: "Create Plan",
    icon: "nc-icon nc-single-02",
    component: CreatePlan,
    layout: "/saas",
  },
  {
    path: "/plan-details/:planId",
    visible: false,
    name: "Plan Details",
    icon: "nc-icon nc-single-02",
    component: PlanDetails,
    layout: "/saas"
  },
  {
    path: "/editPlan/:planId",
    visible: false,
    name: "Edit Plan",
    icon: "nc-icon nc-single-02",
    component: EditPlanGroup,
    layout: "/saas"
  },
  {
    path: "/editfeature",
    visible: false,
    name: "Edit Feature",
    icon: "nc-icon nc-single-02",
    component: EditFeature,
    layout: "/saas",
  },
  // {
  //   collapse: true,
  //   visible:true,
  //   name: "GenAI ",
  //   icon: "nc-icon nc-chart-pie-36",
  //   state: "genAICollapse",
  //   views : [
  //     {
  //       path: "/quota",
  //       name: "Credits",
  //       visible:true,
  //       mini: "F",
  //       component: GenAIQuotaList,
  //       layout: "/genai",
  //     }
  //   ]
  // },
  {
    collapse: true,
    visible: true,
    name: "Genai Credits",
    icon: "nc-icon nc-chart-pie-36",
    status: "genAICollapse",
    views: [
      {
        path: "/credits",
        visible: true,
        name: "Credits",
        mini: "C",
        component: GenAICredits,
        layout: "/genai",
      }
    ]
  },
  {
    path: "/create",
    visible: false,
    name: "Assign Credits",
    icon: "nc-icon nc-alert-circle-i",
    component: CreateGenAICredits,
    layout: "/genai",
  },
  {
    collapse: true,
    visible: true,
    name: "Contracts",
    icon: "nc-icon nc-chart-pie-36",
    status: "contractCollapse",
    views: [
      {
        path: "/contracts",
        visible: true,
        name: "Contracts",
        mini: "C",
        component: Contracts,
        layout: "/contract",
      },
      {
        path: "/payment-links",
        name: "Payment Links",
        visible: true,
        mini: "PL",
        component: PaymentLinks,
        layout: "/contract",
      },
    ]
  },
  {
    path: "/create-payment-links",
    name: "Create Payment Link",
    visible: false,
    name: "Create Payment Links",
    component: CreatePaymentLinks,
    layout: "/contract",
  },
  {
    path: "/view-payment-link",
    name: "View Payment Link",
    visible: false,
    name: "View Payment Link",
    component: ViewPaymentLink,
    layout: "/contract",
  },
  {
    path: "/create",
    visible: false,
    name: "Contracts",
    icon: "nc-icon nc-alert-circle-i",
    component: CreateContract,
    layout: "/contract",
  },
  {
    path: "/invite-enterprise-plan",
    visible: false,
    name: "Invite Enterprise Plan",
    icon: "nc-icon nc-alert-circle-i",
    component: InviteEnterprisePlan,
    layout: "/contract"
  },
  {
    path: "/invitations",
    visible: false,
    name: "Plan Invitations",
    icon: "nc-icon nc-alert-circle-i",
    component: PlanInvitations,
    layout: "/contract"
  },
  {
    collapse: true,
    visible: true,
    name: "Discount Coupon",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsDiscount CouponCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Coupons",
        visible: true,
        mini: "C",
        component: CouponCodeList,
        layout: "/coupon",
      },
      {
        path: "/create",
        visible: false,
        name: "Create Coupon ",
        icon: "nc-icon nc-alert-circle-i",
        component: CreateCoupon,
        layout: "/coupon",
      },
      {
        path: "/edit",
        visible: false,
        name: "Edit  Coupon ",
        component: EditCoupon,
        layout: "/coupon",
      },
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "SaaSify",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsSassifyCollapse",
    views: [
      {
        path: "/featuregroups",
        name: "Feature Groups",
        visible: true,
        mini: "G",
        component: FeatureGroups,
        layout: "/saas",
      },
      {
        path: "/features",
        name: "Features",
        visible: true,
        mini: "F",
        component: Features,
        layout: "/saas",
      },
      {
        path: "/plan-groups",
        name: "Plan Groups",
        visible: true,
        mini: "PG",
        component: PlanGroup,
        layout: "/saas",
      },

      {
        path: "/plans",
        name: "Plan",
        visible: true,
        mini: "P",
        component: Plan,
        layout: "/saas",
      },
      {
        path: "/template",
        name: "Template Details",
        visible: false,
        mini: "P",
        component: PlanGroupTemplates,
        layout: "/saas",
      },


    ]
  },
  {
    path: "/project",
    name: "Project Report",
    visible: false,
    icon: "nc-icon nc-bank",
    component: ProjectReport,
    layout: "/report",
  },
  {
    path: "/publisher",
    name: "Publisher Report",
    visible: false,
    icon: "nc-icon nc-bank",
    component: PublisherReport,
    layout: "/report",
  },
  {
    collapse: true,
    visible: true,
    name: "Tracking",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsTrackingCollapse",
    views: [
      {
        path: "/monthly-stats",
        name: "Monthly Stats",
        visible: true,
        mini: "MS",
        component: MonthlyStats,
        layout: "/project",
      },

      {
        path: "/stats",
        name: "Daily Stats",
        visible: true,
        mini: "DS",
        component: DailyStats,
        layout: "/project",
      },


    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Plugin Info",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsPluginsCollapse",
    views: [
      {
        path: "/users",
        name: "Plugin Users",
        visible: true,
        mini: "U",
        component: PluginUsers,
        layout: "/plugin",
      },

      {
        path: "/projects",
        name: "Projects Plugin Info",
        visible: true,
        mini: "PP",
        component: ProjectsPluginInfo,
        layout: "/plugin",
      },


    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Transactions",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsTransactionCollapse",
    views: [
      {
        path: "/transactions",
        visible: true,
        name: "Plans",
        mini: "P",
        // icon: "nc-icon nc-money-coins",
        component: Transactions,
        layout: "/transaction",
      },
      {
        path: "/paidarticles",
        visible: true,
        name: "Paid Articles",
        mini: "PA",
        // icon: "nc-icon nc-money-coins",
        component: PaidArticlesTransactions,
        layout: "/transaction",
      },
      {
        path: "/transaction-details",
        name: "Transaction details",
        visible: false,
        icon: "nc-icon nc-bank",
        component: TransactionDetails,
        layout: "/transaction",
      },
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Subscription",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsSubscriptionCollapse",
    views: [
      {
        path: "/list",
        visible: true,
        name: "Subscription",
        mini: "S",
        component: Subscriptions,
        layout: "/subscription",
      },
      // {
      //   path: "/details",
      //   name: "subscription details",
      //   visible:false,
      //   icon: "nc-icon nc-bank",
      //   component: Subscriptions,
      //   layout: "/subscription",
      // },
    ]
  },
  {
    path: "/templates",
    name: "Templates",
    visible: true,
    icon: "nc-icon nc-alert-circle-i",
    component: Template,
    layout: "/template",
  },
  {
    path: "/apptemplates",
    name: "App Templates",
    visible: true,
    icon: "nc-icon nc-alert-circle-i",
    component: AppTemplate,
    layout: "/template",
  },
  {
    path: "/createapptemplate",
    name: "Create App Template",
    visible: false,
    component: CreateAppTemplate,
    layout: "/template",
  },
  {
    path: "/templates/list",
    name: "CTV App Templates",
    visible: true,
    icon: "nc-icon nc-alert-circle-i",
    component: CTVAppTemplate,
    layout: "/ctv",
  },
  {
    path: "/template/create",
    name: "Create App Template",
    visible: false,
    component: CreateCTVAppTemplate,
    layout: "/ctv",
  },
  {
    path: "/template/edit",
    name: "Edit CTV App Template",
    visible: false,
    component: EditCTVAppTemplate,
    layout: "/ctv",
  },
  {
    path: "/create",
    name: "Create Template",
    visible: false,
    component: CreateTemplate,
    layout: "/template",
  },
  {
    path: "/edit-tempalte",
    name: "Edit Template",
    visible: false,
    component: UpdateTemplateDetails,
    layout: "/template",
  },
  {
    path: "/customization",
    name: "Edit Template",
    visible: false,
    component: EditTemplate,
    layout: "/template",
  },
  {
    path: "/appcustomization",
    name: "Edit App Template",
    visible: false,
    component: EditAppTemplate,
    layout: "/template",
  },
  {
    path: "/appsettings",
    name: "App General Settings",
    visible: false,
    component: AppSettings,
    layout: "/project",
  },
  {
    path: "/apptemplates",
    name: "Project App Template",
    visible: false,
    component: AppTemplateSetting,
    layout: "/project",
  },
  {
    path: "/users",
    name: "Plugin",
    visible: true,
    icon: "nc-icon nc-alert-circle-i",
    component: PluginUsers,
    layout: "/plugin",
  },
  {
    path: "/dashboard",
    name: "Cache Clean",
    visible: true,
    icon: "nc-icon nc-alert-circle-i",
    component: CacheClean,
    layout: "/cache",
  },
  {
    path: "/clean",
    name: "Cache Clean",
    visible: false,
    icon: "nc-icon nc-alert-circle-i",
    component: ScheduleCacheCleanJob,
    layout: "/cache",
  },

  {
    collapse: true,
    visible: true,
    name: "FAQs",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsFaqCollapse",
    views: [
      {
        path: "/faqgroups",
        name: "FAQ Groups",
        visible: true,
        mini: "G",
        component: FAQGroups,
        layout: "/faq",
      },
      {
        path: "/list",
        name: "FAQs",
        visible: true,
        mini: "F",
        component: FAQs,
        layout: "/faq",
      },
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "WhatsApp",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsWhatsappCollapse",
    views: [
      {
        path: "/messages",
        name: "Messages",
        visible: true,
        mini: "G",
        component: WhatsappMessages,
        layout: "/whatsapp",
      },
      {
        path: "/sendmessage",
        name: "Send Message",
        visible: true,
        mini: "F",
        component: WhatsAppSendMessage,
        layout: "/whatsapp",
      },
      {
        path: "/alerts",
        name: "Whatsapp Alerts",
        visible: true,
        mini: "A",
        component: WhastappAlerts,
        layout: "/whatsapp",
      },
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "ZOHO DESK",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsZohoDeskCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        visible: true,
        mini: "G",
        component: ZohoDeskTickets,
        layout: "/zohodesk",
      },
      {
        path: "/createTicket",
        name: "Create Ticket",
        visible: false,
        mini: "G",
        component: ZohoDeskCreateTicket,
        layout: "/zohodesk",
      },
      {
        path: "/viewTicket",
        name: "View Ticket",
        visible: false,
        mini: "G",
        component: ZohoDeskViewTicket,
        layout: "/zohodesk",
      },
      {
        path: "/editTicket",
        name: "Edit Ticket",
        visible: false,
        mini: "G",
        component: ZohoDeskEditTicket,
        layout: "/zohodesk",
      }



    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Manage Projects",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsManageProjectsCollapse",
    views: [
      {
        path: "/add-to-queue",
        name: "Add Project To Queue",
        visible: true,
        mini: "G",
        component: AddProjectToQueue,
        layout: "/project",
      },
      {
        path: "/delete-projects",
        name: "Delete Projects",
        visible: true,
        mini: "F",
        component: DeleteProjectFromQueue,
        layout: "/project",
      },
    ]
  },
  {
    collapse: true,
    visible: true,
    name: "Manage Service Stack",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsManageServiceStack",
    views: [
      {
        path: "/dashboard",
        name: "Service Stacks",
        visible: true,
        mini: "G",
        component: ServiceStackDashboard,
        layout: "/service-stack",
      },
      {
        path: "/create",
        name: "Delete Service Stack",
        visible: false,
        mini: "F",
        component: CreateServiceStack,
        layout: "/service-stack",
      },
      {
        path: "/details/:id",
        name: "Edit Service Stack",
        visible: false,
        mini: "F",
        component: ServiceStackDetails,
        layout: "/service-stack",
      },
    ]
  },
  {
    path: "/projects-alerts",
    name: "Projects Alerts",
    visible: true,
    icon: "nc-icon nc-single-02",
    component: ProjectAlertDashboard,
    layout: "/project",
  },

  {
    path: "/createfaqgroup",
    visible: false,
    name: "Create FAQ Group",
    icon: "nc-icon nc-single-02",
    component: CreateFAQGroup,
    layout: "/faq",
  },
  {
    path: "/create",
    name: "Create FAQ",
    visible: false,
    icon: "nc-icon nc-alert-circle-i",
    component: CreateFAQ,
    layout: "/faq",
  },
  {
    path: "/edit",
    name: "Edit FAQ",
    visible: false,
    icon: "nc-icon nc-alert-circle-i",
    component: EditFAQ,
    layout: "/faq",
  },
  {
    path: "/user-details",
    name: "User Details",
    visible: true,
    icon: "nc-icon nc-alert-circle-i",
    component: UserDetails,
    layout: "/users"
  },
  {
    path: "/create-user",
    visible: false,
    name: "Create User",
    icon: "nc-icon nc-single-02",
    component: CreateUser,
    layout: "/users",

  },
  {
    path: "/edituserdetails",
    visible: false,
    name: "Edit User Details",
    icon: "nc-icon nc-single-02",
    component: EditUserDetails,
    layout: "/users",
  },
  {
    path: "/permission-details",
    visible: true,
    name: "Permission Details",
    icon: "nc-icon nc-alert-circle-i",
    component: PermissionGroupDetails,
    layout: "/permissions",
  },
  {
    path: "/create-permission-group",
    visible: false,
    name: "Create Permission Group",
    icon: "nc-icon nc-single-02",
    component: CreatePermissionGroup,
    layout: "/permissions",
  },
  {
    path: "/editpermissiongroup",
    visible: false,
    name: "Edit Permission Group",
    icon: "nc-icon nc-single-02",
    component: EditPermissionGroup,
    layout: "/permissions",
  },
  {
    path: "/jwplayer-dashboard",
    visible: true,
    name: "Jwplayer Dashboard",
    icon: "nc-icon nc-single-02",
    component: JwplayerProjects,
    layout: "/project",
  },
  {
    path: "/dashboard",
    visible: true,
    name: "CTV Dashboard",
    icon: "nc-icon nc-tv-2",
    component: CTVDashboard,
    layout: "/ctv",
  },
  {
    path: "/importer-logs",
    visible: true,
    name: "CTV Importers",
    icon: "nc-icon nc-tv-2",
    component: ImporterLogs,
    layout: "/ctv",
  },
  {
    path: "/settings",
    visible: false,
    name: "CTV Dashboard",
    icon: "nc-icon nc-single-02",
    component: CTVGeneralSettings,
    layout: "/ctv",
  },
  {
    path: "/games",
    visible: true,
    name: "Games Management",
    icon: "nc-icon nc-controller-modern",
    component: GamesListing,
    layout: "/dashboard",
    webstoryOnlyRoute: true,
  },
  {
    path: "/create-games",
    visible: false,
    name: "Create Games",
    icon: "nc-icon nc-controller-modern",
    component: CreateGames,
    layout: "/dashboard",
    webstoryOnlyRoute: true,
  },
  {
    path: "/redirection-urls",
    name: "Redirection URLs",
    visible: true,
    icon: "nc-icon nc-send",
    component: RedirectionUrls,
    layout: "/dashboard",
  },
  {
    path: "/user-profiles",
    name: "User Profiles",
    visible: true,
    icon: "nc-icon nc-single-02",
    component: UserProfileDashboard,
    layout: "/dashboard",
  },
  {
    path: "/authz-request",
    name: "Youtube Approvals",
    visible:true,
    icon: "nc-icon nc-single-02",
    component: YoutubeApprovals,
    layout: "/youtube",
  },


  {
    collapse: true,
    visible:true,
    name: "Membership",
    icon: "nc-icon nc-chart-pie-36",
    state: "componentsTransactionCollapse",
    views : [
      {
      path: "/transaction/",
      visible:true,
      name: "Transactions",
      mini: "T",
      component: TransactionsMembership,
      layout: "/membership",
    },
    {
      path: "/subscription",
      visible:true,
      name: "Memberships",
      mini: "S",
      component: SubscriptionsMembership,
      layout: "/membership",
    },
    ]
  },
  {
    path: "/user-detailed-view",
    visible:false,
    icon: "nc-icon nc-single-02",
    component: UserProfileDetailedView,
    layout: "/dashboard",
 
  },

  
  
];


export default routes;
