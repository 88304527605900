import React, { useContext, useEffect, useState , useCallback} from "react";
import { useLocation } from "react-router-dom";
import subscriptionsService from "../../services/api/subscription-membershipl-service.js";
import transactionsService from "../../services/api/transaction-membership-service";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardBody,
  ModalBody,
  Modal,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import projectUsersService from "../../services/api/project-users-service";
// import ProjectContext from '../../ProjectContext'
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";

const UserProfileDetailedView = () => {
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("projectid");

  const [user, setUser] = useState(null); // State to store the user object
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Pagination states
  const [activePage, setActivePage] = useState(1);
  const [records] = useState(10); // Items per page
  const [totalSubscriptions, setTotalSubscriptions] = useState(5);
  const [totalTransactions, setTotalTransactions] = useState(5);
  const [firstRecord, setFirstRecord] = useState(1);
  const [lastRecord, setLastRecord] = useState(5);
  const [renderComponent, setRenderComponent] = useState(true);

  // Subscriptions state with sample data
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);

  // Get the userId from the URL search params
  const userId = new URLSearchParams(window.location.search).get("userid");

    const [transactionFilter, setTransactionFilter] = useState({
      user_id: userId,
      project_id: projectId,
      subscription_id: "",
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionsSub, setTransactionsSub] = useState([]);
  // const projectDetail = useContext(ProjectContext);
  // const projectId = projectDetail.id;

  // Handle page change function
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const firstRec = (pageNumber - 1) * records + 1;
    const lastRec = pageNumber * records;
    setFirstRecord(firstRec);
    setLastRecord(lastRec > totalSubscriptions ? totalSubscriptions : lastRec);
  };

  const handleOpenModal = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  // Review subscription popup function
  const reviewSubscriptionPopup = (subscription) => {
    console.log("Reviewing subscription:", subscription);
    // Add your review logic here
  };

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case -1:
        return "Order could not created";
      case 2:
        return "Success";
      case -2:
        return "Failed";
      default:
        return "";
    }
  };
  useEffect(() => {
    const fetchSubscriptions = async () => {
      const filterDetails = {
        user_id: userId,
        project_id: projectId,
      };

      try {
        let subscriptionsData = await subscriptionsService.getSubscriptions(
          filterDetails,
          activePage,
          records
        );

        if (subscriptionsData.status && subscriptionsData.data) {
          setSubscriptions(subscriptionsData.data.subscriptions);
          setTotalSubscriptions(subscriptionsData.data.count);
        }
        setRenderComponent(true);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setRenderComponent(true);
      }
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const filters = {
      user_id: transactionFilter.user_id,
      project_id: transactionFilter.project_id,
    };
    const loadTransactions = async () => {
      const response = await transactionsService.getTransactions(filters);
      if (response.status && response.data) {
        setTransactions(response.data);
        setRenderComponent(true);
      }
    };
    const loadTotalTransactions = async () => {
      const response = await transactionsService.getTotalTransactions(filters);
      if (response.status) {
        setTotalTransactions(response.data.count);
      }
    };

    loadTransactions();
    loadTotalTransactions();
  }, []);

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await transactionsService.getTransactions(
        transactionFilter,
        activePage,
        records
      );
      if (response.status && response.data) {
        setTransactionsSub(response.data);
        const totalCount = await transactionsService.getTotalTransactions(
          transactionFilter
        );
        if (totalCount.status) {
          setTotalTransactions(totalCount.data.count);
        }
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [transactionFilter, activePage]); // Depend on transactionFilter & activePage

  const handleSubscriptionClick = useCallback((SubscriptionId) => {
    setTransactionFilter((prev) => ({
      ...prev,
      subscription_id: SubscriptionId,
    }));
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [transactionFilter.subscription_id, fetchTransactions]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const result = await projectUsersService.fetchUsers({
          project_id: projectId,
        });

        if (result && result.status === false) {
          setError("No users found");
        } else {
          // Find the user by matching userId
          const matchedUser = result.data.users.find(
            (user) => user.user_id === userId
          );

          if (matchedUser) {
            setUser(matchedUser);
          } else {
            setError("User not found");
          }
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setError("Error fetching users");
      } finally {
        setIsLoading(false);
      }
    };

    console.log("users",user)
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]); // Re-run if userId changes

  if (!user) {
    return <div>No user found!</div>;
  }

  const renderTransactionRows = () => {
    if (!transactions || !transactions.length) {
      return (
        <tr>
          <td colSpan="11" className="text-center">
            {!renderComponent ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <CardTitle tag="h4">No Records Found</CardTitle>
            )}
          </td>
        </tr>
      );
    }

    return transactions.map((transaction, index) => {
   
      return (
        <tr key={transaction.id} >
          <td>{records * (activePage - 1) + index + 1}</td>
          <td>{transaction.user_id?.name || ""}</td>
          <td>{transaction.project_id?.name || ""}</td>
          <td>₹{transaction.amount ? transaction.amount / 100 : ""}</td>
          <td>
          {transaction?.subscription_id?.pg_subscription_id || ""}
          </td>
          <td>{transaction.pg_txn_id || ""}</td>
          <td>
            <Moment format="DD MMM YYYY hh:mm:ss a">
              {transaction.createdat}
            </Moment>
          </td>
          <td>{transaction.type == 1 ? "One time" : "Recurring"}</td>
          <td>
          
              {getStatusText(transaction.status)}
           
          </td>
          <td>
            <Button
              color="info"
              size="sm"
              onClick={() => handleOpenModal(transaction)}
            >
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="content mt-10">
      <Container fluid className="py-4">
        <Row className="align-items-stretch">
          {/* User Profile Card */}
          <Col className="ml-auto mr-auto d-flex flex-column" md="3">
            <Card className="card-signup text-center dp-box flex-grow-1">
              <CardBody>
                <div className="mb-3">
                  <img
                    src={
                      user.picture ||
                      "https://t3.ftcdn.net/jpg/04/85/67/08/360_F_485670810_kCPqkWudAgcVpt8vIRiH95tBrxT33RwN.jpg"
                    }
                    alt={user.name || "User"}
                    className="rounded-circle me-3 mb-2"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src =
                        "https://t3.ftcdn.net/jpg/04/85/67/08/360_F_485670810_kCPqkWudAgcVpt8vIRiH95tBrxT33RwN.jpg"; // Fallback image
                    }}
                  />
                  <div>
                    <h5 className="text-xl font-bold mb-2">{user.name}</h5>
                    <p className="text-sm text-gray-500 mb-0">{user.email}</p>
                    <p className="mb-0 text-base text-gray-500">
                      {user.phone_number || ""}
                    </p>
                  </div>
                </div>

                
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center gap-2">
                    <div
                     className={`rounded-circle ${(!transactions || transactions.length === 0) ? 'bg-danger' : 'bg-success'}`}
                      style={{ width: "15px", height: "15px" }}
                    ></div>
                    <small className="text-muted">Status</small>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold fs-6">
                      {user.createdAt
                        ? new Date(user.createdAt).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        : "-"}
                    </div>
                    <small className="text-muted">Created</small>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold fs-6">
                      {totalSubscriptions || "0"}
                    </div>
                    <small className="text-muted">Subscription</small>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* Subscription History Card */}
          <Col className="ml-auto mr-auto d-flex flex-column" md="9">
            <Card className="text-centercdp-box dp-box flex-grow-1">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <CardTitle tag="h5">Subscription History</CardTitle>
                </div>
              </CardHeader>

              {!renderComponent ? (
                <div className="text-center">
                  <Spinner
                    className="text-center"
                    animation="border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : null}

              <CardBody style={{ height: "25.5vh", overflowY: "auto" }}>
                <Table responsive>
                  <thead className="text-primary">
                    {renderComponent && (
                      <tr>
                        <th width="200px">Sub_ID</th>

                        <th width="150px">Plan ID</th>

                        <th width="300px">Start Date</th>
                        <th width="300px">End Date</th>
                        <th width="200px">Share Url</th>
                        <th width="150px">Status</th>
                        
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {subscriptions && subscriptions.length ? (
                      subscriptions.map((details, index) => (
                        <tr
                          key={details.pg_subscription_id}
                          className={
                            details.status === "cancelled" ? "table-danger" : ""
                          }
                        >
                            <td
                            style={{ cursor: "pointer" , color: "#219ebc"   }}
                          
                            onClick={() =>
                              handleSubscriptionClick(details._id)
                            }
                          >
                            {details.pg_subscription_id || ""}
                          </td>

                          <td>{details.plan_id}</td>

                          <td>
                            {details.start_at
                              ? moment
                                  .unix(details.start_at)
                                  .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>
                            {details.end_at
                              ? moment
                                  .unix(details.end_at)
                                  .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>{details.share_url || ""}</td>
                          <td
                            className={`text-center fw-bold ${
                              details.status === "cancelled"
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {details.status || ""}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="11" className="text-center">
                          {!renderComponent ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          ) : (
                            <CardTitle tag="h4">No Records Found</CardTitle>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>

          {/* Spending Info Cards */}
          {/* <Col className="ml-auto mr-auto d-flex flex-column" md="3">
                        <Card className="card-signup text-center dp-box flex-grow-1">
                            <CardBody className="d-flex flex-column justify-content-center">
                                <div className="d-flex justify-content-evenly align-items-center"
                                    style={{ flexWrap: 'wrap', gap: '10px' }}>
                                    <div className="text-primary fs-4">
                                        <i className="fa fa-bar-chart" />
                                    </div>
                                    <div>
                                        <small className="text-muted d-block">Total spent</small>
                                        <h5 className="mb-0 fs-8">Rs 826</h5>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        <Card className="card-signup text-center dp-box flex-grow-1">
                            <CardBody className="d-flex flex-column justify-content-center">
                                <div className="d-flex justify-content-evenly align-items-center"
                                    style={{ flexWrap: 'wrap', gap: '10px' }}>
                                    <div className="text-primary fs-4">
                                        <i className="fa fa-bar-chart" />
                                    </div>
                                    <div>
                                        <small className="text-muted d-block">This Month</small>
                                        <h5 className="mb-0 fs-8">Rs 280</h5>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col> */}
        </Row>
        <Card className="text-centercdp-box dp-box">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <CardTitle tag="h5">Transaction History</CardTitle>
            </div>
          </CardHeader>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th>Project Name</th>
                      <th>Transaction Amount(INR)</th>
                      <th>Membership ID</th>
                      <th>Transaction ID</th>
                      <th>Transaction Date</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderTransactionRows()}</tbody>
                </Table>
              </CardBody>
            </Col>
          </Row>
        </Card>

        <Modal
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                size="lg"
              >
                <ModalBody className="p-3 position-relative">
                  {/* Close Button */}
                  <div
                    className="position-absolute d-flex align-items-center justify-content-center"
                    style={{
                      right: "12px",
                      top: "8px",
                      cursor: "pointer",
                      zIndex: 1,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={() => setIsModalOpen(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} className="text-white" />
                  </div>

                  {/* Transaction Details Section */}
                  <div className="mb-3">
                    <h5 className="mb-3">Transaction Details</h5>

                    <div className="border rounded">
                      {transactionsSub.length > 0 ? (
                        <Table responsive>
                          <thead className="text-primary">
                            <tr>
                              <th className="text-left">#</th>
                              <th className="text-left">User Name</th>
                              <th className="text-left">
                                Transaction Amount(INR)
                              </th>
                              <th className="text-left">
                                Membership ID 
                              </th>
                              <th className="text-left">Transaction Id</th>
                              <th className="text-left">Transaction Date</th>
                              <th className="text-left">Type</th>
                              <th className="text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionsSub.map((transaction, index) => {
                              const rowNumber =
                                records * (activePage - 1) + index + 1;
                             

                              return (
                                <tr key={transaction.id} >
                                  <td className="text-left">{rowNumber}</td>
                                  <td className="text-left">
                                    {transaction?.user_id?.name || ""}
                                  </td>
                                  <td className="text-left">
                                    ₹{(transaction?.amount || 0) / 100}
                                  </td>
                                  <td className="text-left">
                                    ₹
                                    {transaction?.subscription_id?.pg_subscription_id || ""}
                                  </td>
                                  <td className="text-left">
                                    {transaction.pg_txn_id || ""}
                                  </td>
                                  <td className="text-left">
                                    <Moment format="DD MMM YYYY hh:mm:ss a">
                                      {transaction.createdat}
                                    </Moment>
                                  </td>
                                  <td className="text-left">
                                    {transaction.type === "1"
                                      ? "One time"
                                      : "Recurring"}
                                  </td>
                                  <td className="text-left">
                                    {transaction.status === 1
                                      ? "Order Created"
                                      : getStatusText(transaction.status)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
        <Modal
              isOpen={modalOpen}
              toggle={() => setModalOpen(false)}
              size="md"
            >
              <ModalBody className="p-3 position-relative">
                {/* Close Button */}
                <div
                  className="position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    right: "12px",
                    top: "8px",
                    cursor: "pointer",
                    zIndex: 1,
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={() => setModalOpen(false)}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-white" />
                </div>

                {/* Transaction Details Section */}
                <div className="mb-3">
                  <h6 className="mb-2">Transaction Details</h6>
                  <div className="border rounded">
                    <Row className="g-0">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Transaction ID
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Order ID</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.order_id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          PG Transaction ID
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.pg_txn_id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          PG Transaction Message
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.pg_txn_message || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Amount</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          ₹{selectedTransaction?.amount / 100 || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Status</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {getStatusText(selectedTransaction?.status)}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Transaction Date
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          <Moment format="DD MMM YYYY hh:mm:ss a">
                            {selectedTransaction?.createdat}
                          </Moment>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div>
                  <h6 className="mb-2">Membership Details</h6>
                  <div className="border rounded">
                    <Row className="g-0">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Membership ID
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id
                            ?.pg_subscription_id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Paid Count</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.paid_count ||
                            ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Status</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.status || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Type</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.total_count ===
                          1
                            ? "One Time"
                            : selectedTransaction?.subscription_id?.total_count
                            ? "Recurring"
                            : ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Share Url</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.share_url ||
                            ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Membership Start Date
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.start_at
                            ? moment
                                .unix(
                                  selectedTransaction?.subscription_id?.start_at
                                )
                                .format("DD MMM YYYY")
                            : ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Membership End Date
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.end_at
                            ? moment
                                .unix(
                                  selectedTransaction?.subscription_id?.end_at
                                )
                                .format("DD MMM YYYY")
                            : ""}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </ModalBody>
            </Modal>
      </Container>
    </div>
  );
};

export default UserProfileDetailedView;
