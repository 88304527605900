import axios from "axios";


const fetchUsers = async (formdata) => {
    try {
        console.log(formdata);
        const response = await axios.post('/api/projects/get-project-users-profile', formdata);
        // console.log("response",response);
        return response.data;
    } catch (error) {
        console.error('Error in fetching users:', error.message || error);
        throw new Error('Failed to fetch users. Please try again later.');
    }
};

const fetchUserTokenDetails = async (user_id) => {
    try {
        const response = await axios.post('/api/projects/get-user-token-details', { user_id });
        return response.data;
    } catch (error) {
        console.error('Error in fetching user token details:', error.message || error);
        throw new Error('Failed to fetch user token details. Please try again later.');
    }
};


const exportUsers = async (formdata) => {
    try {
        const response = await axios.post('/api/projects/export-project-users',formdata,{
            responseType : 'arraybuffer'
        });
        console.log("response",response);
        return response;
    } catch (error) {
        console.error('Error in exporting users:', error.message || error);
        throw new Error('Failed to export users. Please try again later.');
    }
};

export default {
   fetchUsers,
   fetchUserTokenDetails,
   exportUsers,
};