import axios from "axios";

const getTransactionDetailsByContractId=async (contract_id)=>{
    const formdata={
        contract_id:contract_id
    }
const response=await axios.post("/api/transactionMembership/get-transaction-details-bycontractId",formdata)
return response.data;
}
const getTransactions = async(formdetails,page,records) =>{
    console.log(formdetails)
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/transactionMembership/get-transactions',formdata);
    return response.data;
}
const getTotalTransactions = async(formdata) =>{
    const response = await axios.post('/api/transactionMembership/get-total-transactions',formdata);
    return response.data;
}
const getPaidArticlesTransactions = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/transactionMembership/get-paid-articles-transactions',formdata);
    return response.data;
}

const reconcileTransaction = async(transactionId) =>{
    const response = await axios.post('/api/transactionMembership/reconcile-transactions',{
        transactionId
    });
    return response.data;
}





export default {
    getTransactionDetailsByContractId,
    getTransactions,
    getTotalTransactions,
    getPaidArticlesTransactions,
    reconcileTransaction,
   
}