import axios from 'axios';


const getSubscriptions = async(formdetails,page,records) =>{
    console.log("service")
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/subscription-membership/get-subscriptions',formdata);
    return response.data;
}

const cancelSubscription = async (id) => {
   
    try {
        const response = await axios.post('/api/subscription-membership/cancel-subscription', { subscriptionId: id });
        console.log("cancel" , response.data)
        return response.data;
    } catch (error) {
        console.error("Error canceling subscription:", error);
        throw error;
    }
};
export default {
    getSubscriptions,
    cancelSubscription
}